let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Aesir Minecraft",
    "role": "",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "Aesir, bir RPG Faction Towny ve Skyblock sunucusudur. 2021'de kurulan, Minecraft oyuncularına Türkiye'nin en iyi RPG keyfini yaşatmayı hedefleyen ve bunu başaran, Türkiye'de RPG konusunda birinci sıraya adını yazdırmış olan Aesir Minecaft sunucusunda seni de görmek isteriz.",
    "socialLinks":[
        {
          "name":"magaza",
          "url":"https://store.aesirmc.com/",
          "className":"fa fa-shopping-cart",
          "aciklama":"Mağaza"
        },
        {
          "name":"youtube",
          "url":"https://www.youtube.com/@AesirMC",
          "className":"fa fa-youtube-play",
          "aciklama":"YouTube"
        },
        {
          "name":"instagram",
          "url":"https://www.instagram.com/aesir.mc/",
          "className":"fa fa-instagram",
          "aciklama":"Instagram"
        }
      ],
    "aboutme":"Merhabalar ben Ahmet, Tyris de diyebilirsiniz. 2 yıl önce Aesir Minecraft sunucusunu kurdum ve günümüzde de ekibimle birlikte büyük bir hızla geliştiriyorum. Bu işe başlarken asıl amacımız Türkiye'ye MMORPG tadında bir Minecraft sunucusu kazandırmaktı ve bunu başardığımıza inanıyorum. Türkiye'ye klasik Minecraft sunucularından ayrı bir kültür katacağız ve bunu birlikte, \"Aesir Her Daim\" olması dileği ile yapacağız.",
    "address":"Türkiye",
    "website":"https://rbhatia46.github.io",
    "education":[
      {
        "UniversityName":"The LNM Insitute of Information Technology",
        "specialization":"Some specialization",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Some University",
        "specialization":"Some specialization",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData
