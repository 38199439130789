import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="https://cdn.discordapp.com/attachments/1016099733686722682/1109945050936393769/b2ec502cb6834b9608317380ef4e5f4c.png" alt="" />

            </div>

            <div className="nine columns main-col">

               <h2>Hakkımızda</h2>
               <p>
               {
                 resumeData.aboutme
               }
               </p>

               
            </div>
         </div>
      </section>
    );
  }
}
