import React, { Component } from 'react';
export default class Header extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      
      <header id="home">
         <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li className="current"><a className="smoothscroll" href="#home">Aesir</a></li>
               <li><a href="https://store.aesirmc.com" target="_blank" rel="noopener noreferrer"><i class="fas fa-shopping-cart"></i> Store</a></li> 
               <li><a href="https://wiki.aesirmc.com" target="_blank" rel="noopener noreferrer">Wiki</a></li> 
               <li><a className="smoothscroll" href="#about">Hakkımızda</a></li>
               <li><a className="smoothscroll" href="#resume">Haritalar</a></li>
            </ul>
         </nav>

         <div className="row banner">
            <div className="banner-text">
               <h1 className="responsive-headline">{resumeData.name}</h1>
               <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>{resumeData.role}{resumeData.roleDescription}
               </h3>
               <hr/>
               <ul className="social">
                  {
                    resumeData.socialLinks && resumeData.socialLinks.map(item =>{
                      return(
                              <li key={item.name}>
                                <a href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.className}></i></a>
                              </li>
                            )
                          }
                    )
                  }
                  <li key="tiktok">
                  <a href="https://www.tiktok.com/@aesirdc" target="_blank" rel="noopener noreferrer"><i class="fab fa-tiktok"></i></a>
                  </li>
                  <li key="discord">
                  <a href="https://discord.gg/aesirdc" target="_blank" rel="noopener noreferrer"><i class="fab fa-discord"></i></a>
                  </li>
                  <li key="wiki">
                  <a href="https://wiki.aesirmc.com/" target="_blank" rel="noopener noreferrer"><i class="fas fa-book"></i></a>
                  </li>
               </ul>
            </div>
         </div>

         <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
         </p>

      </header>
      </React.Fragment>
    );
  }
}
