import React, { Component } from 'react';
export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

         <div className="row education">

            <div className="three columns header-col">
               <h1><span>MMORPG MAP</span></h1>
            </div>

            <div className="nine columns main-col">
            <div className="row item">
                       <div className="twelve columns">
                          <div className="map__item">
                           <h1>Yakında..</h1>
                          </div>
                       </div>
                    </div>
            </div>
         </div>
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>TOWNY MAP</span></h1>
            </div>

            <div className="nine columns main-col">
                <div className="row item">
                       <div className="twelve columns">
                          <div className="map__item">
                           <h1>Yakında..</h1>
                          </div>
                       </div>
                    </div>
            </div> 
         </div>

      </section>
    );
  }
}